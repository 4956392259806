import axios from "axios";
import {BASEROCK_API_URL} from "../settings";

interface SendRequest {
  requestType: "post" | "get" | "put" | "options" | "delete" | "patch";
  requestHeader?: string;
  requestData?: any;
  requestParams?: any;
  endpoint?: any;
  headers?: any;
}

const sendRequest = async ({
  requestType,
  requestHeader,
  requestData,
  requestParams,
  headers,
}: SendRequest) => {
  const credential = localStorage.getItem(
    `${process.env.REACT_APP_GCP_PROJECT}_access`
  );

  console.log(process.env.REACT_APP_GCP_PROJECT);
  console.log("Credential:", credential);
  const defaultHeaders = {
    Accept: "application/json",
  };

  // Construct the full URL
  const fullUrl = `${BASEROCK_API_URL}/${requestHeader}`;
  console.log("Full URL:", fullUrl);

  try {
    const response = await axios({
      method: requestType,
      url: fullUrl,
      data: requestData,
      params: requestParams,
      headers: headers
        ? { ...headers, ...defaultHeaders }
        : { Authorization: `Bearer ${credential}`, ...defaultHeaders },
    });

    console.log("Response:", response);

    return response.data;

  } catch (error) {
    // Advanced error handling
    if (axios.isAxiosError(error)) {
      // Axios-specific error handling
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error('Error response:', {
          status: error.response.status,
          data: error.response.data,
          headers: error.response.headers,
        });
        // Handle specific status codes here
        if (error.response.status === 401) {
          // Handle unauthorized error
          console.error('Unauthorized access - perhaps redirect to login?');
        } else if (error.response.status === 404) {
          // Handle not found error
          console.error('Resource not found');
        } else if (error.response.status === 500) {
          // Handle server error
          console.error('Internal server error');
        } else if (error.response.status === 502) {
          // Handle bad gateway error
          console.error('Bad Gateway - the server received an invalid response from the upstream server.');
        }
      } else if (error.request) {
        // No response was received from the server
        console.error('No response received:', error.request);
      } else {
        // Something happened in setting up the request
        console.error('Error setting up request:', error.message);
      }
    } else {
      // Non-Axios error
      console.error('Unexpected error:', error);
    }

    // Optionally, you can throw the error to be handled by the caller
    throw error;
  }
};

export default sendRequest;
